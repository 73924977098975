import { StaticImage } from "gatsby-plugin-image"
import Map from "./map"
import React from "react"
import ctl from "@netlify/classnames-template-literals"
import { DesktopBreakpoint } from "../../../breakpoints"
import MobileMap from "./map/mobile"
import ThreePhones from "../../../animations/three-phones"
import LadyWithPhone from "../../../animations/lady-with-phone"

export const slides = [
  {
    heading: (
      <>
        Create wealth differently. <br />
        Explore endless Possibilities
      </>
    ),
    description: (
      <span className='md-max:max-w-[365px] inline-block'>
        Trade 1000+ assets in the financial markets profitably <DesktopBreakpoint/>
        with trusted support and educational resources.
      </span>
    ),
    buttons: [
      {
        label: `Open An Account`,
        url: `https://clients.eagleglobalmarkets.com/#/`,
        isExternal: true,
      },
      {
        label: `Try Demo Trading`,
        url: "https://mt4.egmarkets.trade/",
        isExternal: true,
      },
    ],
    image: (
      <div className='has-net-bg md:max-w-[655px] md:w-[45.48vw] md:mb-0 mb-[30px] flex items-center justify-center'>
        <figure className='w-[84%]'>
          <ThreePhones />
        </figure>
      </div>
    ),
  },
  {
    heading: (
      <>
        New to trading?
        <br />
        Need professional help?
      </>
    ),
    description: (
      <span className='md-max:max-w-[340px] md:max-w-[400px] inline-block'>
        Learn forex trading, indices and commodities trading and stock trading with our
        wide range of educational guides & webinars.
      </span>
    ),
    buttons: [
      {
        label: `Attend a webinar`,
        url: `/academy/webinars`,
      },
      {
        label: `Try Demo Trading`,
        url: "https://mt4.egmarkets.trade/",
        isExternal: true,
      },
    ],

    image: (
      <div className='md-max:relative'>
        <div className="map-image relative md:absolute lg:top-[16%] top-[100%] lg:right-0 right-[50%] transform lg:translate-x-0 translate-x-[50%] lg:w-[62.5%] w-full">
          {/*<StaticImage*/}
          {/*  src="../../../../assets/images/pages/home/slider/map.webp"*/}
          {/*  alt="Map of the world"*/}
          {/*/>*/}
          <div className='md-max:hidden'>
            <StaticImage
              src="../../../../assets/images/pages/home/slider/map.webp"
              alt="Map of the world"
            />
          </div>
          <div className='md:hidden'>
            <StaticImage
              src="../../../../assets/images/pages/home/slider/map-m.png"
              alt="Map of the world"
            />
          </div>
        </div>
        <Map
          className={
            ctl(`
              map-svg 
              absolute lg:top-[-4%] lg-max:bottom-0
              lg:right-0 right-[50%]
              translate-x-[50%]
              transform lg:translate-x-0
              lg:w-7/12 w-full
              md-max:hidden
            `)
          }
        />
        <MobileMap
          className={
            ctl(`
              md:hidden w-[90%]
              absolute top-[-40px] right-[35px]
            `)
          }
        />
      </div>
    ),
    isMap: true
  },
  {
    heading: (
      <>
        Find trading suggestions,
        <br />
        Place profitable trades.
      </>
    ),
    description: (
      <span className='md-max:max-w-[300px] inline-block'>
        Receive signals that increase your chances of
        <DesktopBreakpoint/> placing successful trades! Sign up now.
      </span>
    ),
    buttons: [
      {
        label: `Receive Signals`,
        isExternal: true,
        url: 'http://eepurl.com/hDPu1X'
      },
      {
        label: `How To Use Signals`,
        isExternal: true,
        url: 'https://youtu.be/RLk1otTkZt0'
      },
    ],

    image: (
      <div className='has-net-bg md:w-[53vw] md:max-w-[655px] md:mb-0 mb-[30px] flex items-center justify-center'>
        <figure className='w-full md:w-[79%]'>
          <LadyWithPhone/>
        </figure>
      </div>
    ),
  },
]
