import React from "react"
import ctl from "@netlify/classnames-template-literals"


const HomeFeaturesList = ({ features }) => {
  return (
    <>
      {features.map((feature, index) => {
        const iconStyle = ctl(`block mb-[20px]`)

        const headingStyle = ctl(`text-[19px] mb-[18px]`)

        return (
          <section key={index}>
            <img src={feature.icon} alt={feature.name} className={iconStyle} />
            <h4 className={headingStyle}>{feature.name}</h4>
            <p className="mt-0">{feature.description}</p>
          </section>
        )
      })}
    </>
  )
}

HomeFeaturesList.propTypes = {}

export default HomeFeaturesList
