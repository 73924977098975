import React from "react"
import ctl from "@netlify/classnames-template-literals"
import PropTypes from "prop-types"
import crossIcon from "icons/cross-orange.svg"

const HomeSectionHeading = ({ heading, description, hasCross, headingClassName }) => {
  const headerStyle = ctl(`text-center  max-w-screen-sm mx-auto ${headingClassName}`)

  const paragraphStyle = ctl(`md:text-base max-w-xs mx-auto font-medium`)

  const crossIconStyle = ctl(`shape md:w-auto w-[50px] bottom-[0] right-[-4%]`)

  return (
    <header className={headerStyle}>
      <h1 className="relative">
        {heading}
        {hasCross && <img src={crossIcon} alt="" className={crossIconStyle} />}
      </h1>
      <p className={paragraphStyle}>{description}</p>
    </header>
  )
}

HomeSectionHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
}

HomeSectionHeading.defaultProps = {
  heading: "Kindly enter a title here",
  description: "Additional information about the section goes here",
}

export default HomeSectionHeading
