import React from "react"
import { Button } from "../../utils"
import ctl from "@netlify/classnames-template-literals"
import LearnFromMastersAnimation from "../../animations/learn-from-masters-animation"

const LearnFromMasters = () => {
  return (
    <section className={sectionStyling}>
      <div className='lg:pt-[112px] lg-max:text-center md-max:max-w-[90%] mx-auto md:min-w-[360px]'>
        <h3 className='text-[32px] leading-none lg:text-[40px] font-extrabold mb-4 lg:leading-[1.25]'>
          Attend Industry
          <br/>
          standard webinars.
        </h3>
        <p className='text-base font-medium mt-0'>
          We deliver a range of tailored webinars to suit all <br className='lg-max:hidden'/>
          levels of expertise, from beginners to pro traders.
        </p>
        <Button
          hasArrow
          regularFontWeight
          hasMargin={false}
          type={null}
          label="View Webinars"
          url="/academy/webinars"
          className="!text-lg !font-bold md:!pl-0 border-none inline-flex items-center text-blue"
          arrowBlue
        />
      </div>
      <figure className={imageWrapperStyling}>
        <div className='z-[11]'>
          <LearnFromMastersAnimation />
        </div>
      </figure>
    </section>
  )
}

const sectionStyling = ctl(
  `
    md:mx-[15%] xl:ml-[23%] xl:mr-[20.9%] flex gap-x-[100px] xl:items-left
    lg-max:flex-col gap-y-[30px] lg-max:items-center z-20 relative
  `
)

const imageWrapperStyling = ctl(
  `
    flex-shrink-0 relative
    flex items-center justify-center
    lg:w-[378px] lg:h-[378px] rounded-[50%]
    md-max:w-[326px] md-max:h-[326px]
    xs-max:!w-[240px] xs-max:!h-[240px]
    bg-white border border-brownish-grey
    p-4 xs:p-[30px] md:p-10
  `
)

export default LearnFromMasters
