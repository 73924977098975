import React from "react"
import ctl from "@netlify/classnames-template-literals"

const MobileFeatures = ({ reverse, features, noMargin }) => {
  const sectionStyle = ctl(`
  flex items-center
  ${reverse && "flex-row-reverse"}
  ${!noMargin && 'mb-10'}
  md:hidden
  `)

  const sideburnsStyle = ctl(`
    h-[422px] w-[40px] bg-blue opacity-4 flex-shrink-0
  `)

  const featuresStyle = ctl(`
    ${reverse ? 'pl-[125px] pr-[50px]' : 'px-[85px]'}
    py-[68px]
    ${reverse ? 'rounded-tr-[75px] rounded-br-[75px]' : 'rounded-tl-[75px] rounded-bl-[75px]'}
    border border-blue
    gap-y-[70px] grid
    flex-grow
    bg-white z-[2]
  `)

  return (
    <section className={sectionStyle}>
      <div className={sideburnsStyle}/>
      <div className={featuresStyle}>
        {features.map((feature, index) => {
          const iconStyle = ctl(`block mb-[20px]`)

          const headingStyle = ctl(`text-[19px] mb-[18px]`)

          return (
            <section key={index}>
              <img src={feature.icon} alt={feature.name} className={iconStyle} />
              <h4 className={headingStyle}>{feature.name}</h4>
              <p className="mt-0 font-medium leading-[21px]">{feature.description}</p>
            </section>
          )
        })}
      </div>
    </section>
  )
}

export default MobileFeatures