import React from "react"
import HomeSlide from "./slide"

import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { slides } from "./slides"
import HomeSliderNavigationArrows from "./navigation-arrows"
import "./home-slider.css"

SwiperCore.use([Autoplay, Navigation, Pagination])

const desktopTokens = [
  "forex",
  "gold",
  "oil",
  "nasdaq",
  "tesla",
  "bitcoin"
]

const mobileTokens = [
  "forex-m",
  "gold-m",
  "oil-m",
  "nasdaq-m",
  "tesla-m",
  "bitcoin-m"
]

const onSlideChange = (swiper) => {
  if(swiper.activeIndex === 2){
    setTimeout(() => {
      const tokens = window.innerWidth > 768 ? desktopTokens : mobileTokens
      tokens.forEach((token, index) => {
        setTimeout(() => {
          document.getElementById(token)?.classList.add('active-asset')
        }, 150 * (index + 1))
      })
    }, 250)
  }else{
    desktopTokens.concat(mobileTokens).forEach((token) => {
      document.getElementById(token)?.classList.remove('active-asset')
    })
  }
}

const HomeSlider = () => {
  return (
    <div className='relative home slider-wrapper'>
      <Swiper
        slidesPerView={1}
        autoplay={{
          delay: 5000,
          pauseOnMouseEnter: true
        }}
        autoHeight
        loop={true}
        navigation={{
          nextEl: ".next-slider-arrow",
          prevEl: ".previous-slider-arrow"
        }}
        pagination={{ clickable: true }}
        className="relative h-max-content"
        onSlideChange={onSlideChange}
        breakpoints={{
          768: {
            autoHeight: false
          }
        }}
      >
        {slides.map((slideProps, index) => {
          return (
            <SwiperSlide observer observeParents key={index}>
              <HomeSlide {...slideProps} />
            </SwiperSlide>
          )
        })}

        <HomeSliderNavigationArrows />
      </Swiper>
    </div>
  )
}

export default HomeSlider
