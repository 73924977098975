import React from "react"
import { Button } from "../../utils"
import ctl from "@netlify/classnames-template-literals"
import { useInView } from "react-intersection-observer"
import CurrencyTight from "../../animations/currency-tight"

const HomeCopytrading = () => {
  const { ref, inView } = useInView({
    threshold: 0.7,
    triggerOnce: true
  });

  return (
    <section className={sectionStyling}>
      <figure className={imageWrapperStyling}>
        <div className={`${imageStyling} lottie-animation`} ref={ref}>
          <CurrencyTight/>
        </div>
      </figure>
      <div className='lg:pt-[30px] lg-max:text-center max-w-[90%] mx-auto md:min-w-[290px]'>
        <h3 className='text-[32px] leading-none lg:text-[40px] font-extrabold mb-4 lg:leading-[1.25]'>
          Don’t want to <br/>
          trade yourself?
        </h3>
        <p className='text-base font-medium mt-0'>
          Follow seasoned traders on our copy <br className='lg-max:hidden'/>
          trading platform and earn when they do!
        </p>
        <Button
          hasArrow
          regularFontWeight
          hasMargin={false}
          type={null}
          label="Try Copytrading"
          url="/platforms/copytrade"
          className="!text-lg text-blue !font-bold md:!pl-0 border-none inline-flex items-center"
          arrowBlue
        />
      </div>
    </section>
  )
}

const sectionStyling = ctl(
  `
    md:mb-[20px] md:pl-[27px] md:mx-[15%] xl:ml-[23%] xl:mr-[20.9%] flex gap-x-[130px] xl:items-left
    lg-max:flex-col-reverse lg-max:space-y-[72px] lg-max:space-y-reverse lg-max:items-center lg-max:mb-[94px]
  `
)

const imageWrapperStyling = ctl(
  `
    flex-shrink-0 relative
    lg:w-[360px] lg:h-[360px] rounded-[50%]
    lg-max:w-[300px] lg-max:h-[300px]
    md-max:w-[268px] md-max:h-[268px]
    xs-max:!w-[240px] xs-max:!h-[240px]
    bg-white border border-brownish-grey
    md-max:z-[11]    
  `
)

const imageStyling = ctl(`
  absolute xl:w-[424px]
  xl:!bottom-2 xl:!left-[-55px]
  lg:!bottom-[16px] lg:!left-[-48px]
  lg-max:max-w-[121%] lg-max:w-[385px] lg-max:relative 
  xs:bottom-[55px] xs:left-[-46px] z-[11]
  bottom-[51px] left-[-41px]
`)

export default HomeCopytrading
