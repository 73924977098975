import React from "react"
import ctl from "@netlify/classnames-template-literals"
import Button from "../../../utils/button"
import financialComissionBadge from "assets/images/pages/home/financial-commission.webp"

const HomeSlide = ({ heading, description, image, buttons, isMap, containerClassName }) => {
  const buttonStyle = 'md-max:flex md-max:items-center md-max:justify-center md-max:text-sm md-max:h-[50px]';

  return (
    <div className="relative md:pl-[8.6%] md:pr-[43px] mx-auto">
      <div
        className={ctl(`${containerStyle} max-w-[1300px] mx-auto ${containerClassName} ${isMap && 'pb-[30px]'}`)}
      >
        <header className={headerStyle}>
          <p className={tagStyle}>Trade the financial markets</p>
          <h2 className={headingStyle}>{heading}</h2>
          <p className={descriptionStyle}>{description}</p>
          <div className={buttonGroupStyle}>
            <Button
              type="primary"
              {...buttons[0]}
              inGroup
              className={`${buttons[0].className} ${buttonStyle}`}
            />
            <Button
              type="secondary"
              {...buttons[1]}
              inGroup
              className={`${buttons[1].className} ${buttonStyle}`}
            />
          </div>

          <a href='https://financialcommission.org/eagle-global-markets/' target='_blank' className={financialCommissionStyle}>
            <img src={financialComissionBadge} alt="" className="w-full" />
          </a>
        </header>

        <div className={imageWrapperStyle}>{image}</div>
        <a href='https://financialcommission.org/eagle-global-markets/' target='_blank' className={`md:hidden hidden mx-auto max-w-[307px] ${isMap ? 'mb-[26px]' : 'mb-[60px]'}`}>
          <img src={financialComissionBadge} alt="" className="w-full" />
        </a>
      </div>
    </div>
  )
}

export default HomeSlide

const containerStyle = ctl(
  ` flex lg:flex-row flex-col lg:pt-[88px] justify-between  `
)

const headerStyle = ctl(
  `md-max:px-3 lg:text-left text-center max-w-[550px] md:mx-0 mx-auto md-max:w-full`
)
const tagStyle = ctl(
  `hidden md:block text-[11px] md:mt-0 mt-[44px] mb-0 font-bold uppercase swiper-no-swiping`
)
const headingStyle = ctl(
  `md:text-[40px] md:mt-[10px] mt-[40px] pt-0 text-[30px] font-extrabold swiper-no-swiping`
)
const descriptionStyle = ctl(`md:text-xl font-normal text-base lg:mt-[30px] swiper-no-swiping`)

const buttonGroupStyle = ctl(
  `md:mt-[40px] lg:mx-0 mx-auto mt-[28px] button-group
   md-max:flex-row md-max:max-w-none
   md-max:justify-between
   md-max:gap-x-5
   md-max:px-2
   md-max:max-w-[383px] md-max:w-full
   `
)
const imageWrapperStyle = ctl(
  `md:w-auto lg:mt-0 mt-[54px] md:mx-0 mx-auto w-full`
)

const financialCommissionStyle = ctl(
  ` md:w-[343px] lg:mx-0 mx-auto w-full
  md:mt-[60px] mt-[40px] md-max:hidden hidden`
)
