import React from "react"
import HomeSectionHeading from "../section-title"
import { Container } from "components/utils"
import ctl from "@netlify/classnames-template-literals"
import HomeFeaturesList from "./list"

import ellipseShape from "icons/ellipse.svg"
import { StaticImage } from "gatsby-plugin-image"
import MobileFeatures from "./mobile-features"
import rangeIcon from "icons/features/range.svg"
import fundsSafetyIcon from "icons/features/funds-safety.svg"
import spreadsIcon from "icons/features/spreads.svg"
import multipleFundingIcon from "icons/features/multiple-funding.svg"
import globallyLicensed from "icons/features/globally-licensed.svg"
import tradingTipsIcon from "icons/features/trading-tips.svg"
import "./index.css"
import { DesktopBreakpoint } from "../../../breakpoints"

const DesktopBr = () => <br className='desktop-breakpoint'/>
const MobileBr = () => <br className='mobile-breakpoint'/>

const features = [
  {
    icon: rangeIcon,
    name: (
      <>
        Wide range of <DesktopBr/>
        trading <MobileBr/> Instruments
      </>
    ),
    description: (
      <>
        Choose from 1000+ instruments
        <br />
        across the financial  markets.
      </>
    )
  },
  {
    icon: spreadsIcon,
    name: (
      <>
        Tightest spreads <br/>
        you can find
      </>
    ),
    description: (
      <>
        Tightest spreads across <MobileBr/> Nigeria <DesktopBr/>
        & Africa. Do your <MobileBr/> research
      </>
    )
  },
  {
    icon: multipleFundingIcon,
    name: (
      <>
        Multiple funding <br />
        options
      </>
    ),
    description: (
      <>
        We’ve provided a range of <br/>
        payment methods for your <br/>
        convenience.
      </>
    )
  },
  {
    icon: globallyLicensed,
    name: (
      <>
        Globally licensed <br />& regulated
      </>
    ),
    description: (
      <>
        We’re regulated by the world’s biggest <DesktopBreakpoint/>
        supervision authorities <MobileBr/> so rest assured, <DesktopBr/>
        you’re in <MobileBr/> safe hands
      </>
    )
  },
  {
    icon: fundsSafetyIcon,
    name: (
      <>
        Your funds are <br/>
        safe with us
      </>
    ),
    description: (
      <>
        Your funds are kept secure <DesktopBr/>
        and <MobileBr/>
        all of their personal information <br/>
        is guarded under SSL encryption
      </>
    )
  },
  {
    icon: tradingTipsIcon,
    name: (
      <>
        Trading tips from <br/>
        seasoned traders
      </>
    ),
    description: (
      <>
        We want you making
        <br/>
        profit and for this reason
        <br/>
        we send you tips!
      </>
    )
  }
]

const HomeFeatures = () => {
  const featuresListSectionStyle = ctl(
    `border border-blue rounded-[75px] px-[57px] pt-[38px] pb-[60px]  grid md:grid-cols-3 bg-white sm:grid-cols-2 md:gap-x-[60px] gap-x-16 lg:gap-x-[120px] gap-y-[105px]`
  )

  const wrapperStyle = ctl(
    `md:mt-[30px] md:pt-[105px] pt-[50px] relative md:overflow-hidden`
  )

  const ellipseShapeStyle = ctl(
    `shape md:w-[100vw] md:left-0 right-[-20%] md:top-[50px] top-4 hidden md:block`
  )

  const sideBurnsStyle = ctl(
    "h-[70%] bg-blue opacity-4"
  )
  return (
    <div className={wrapperStyle}>
      <Container size="large" className="md:px-[50px]">
        <img src={ellipseShape} alt="" className={ellipseShapeStyle} />
        <div className='w-full absolute left-0 top-[-20px] md:hidden'>
          <StaticImage
            src="../../../../assets/images/pages/home/services-curve-m.svg"
            className="md:w-[188px] md:mb-0 mb-12"
            alt='Curve for illustration purposes'
          />
        </div>
        <div className="flex items-start justify-center">
          <HomeSectionHeading
            headingClassName="ml-0 mr-0 md:mr-[72px] mb-[30px] md:mb-0"
            heading={
              <span className="inline-flex max-w-[422px] flex-shrink-0 text-left">
                Our services foster
              <br /> your trading success{" "}
              </span>
            }
            description=""
            hasCross
          />
          <div className=" hidden md:block">
            <StaticImage
              src="../../../../assets/images/pages/home/service-foster.webp"
              className="md:w-[188px] md:mb-0 mb-12"
              alt="Our services foster your trading success"
            />
          </div>
        </div>

        <div className="hidden md:grid grid-cols-[48px,1fr,48px] items-center  relative md:top-[-144px]">
          <div className={sideBurnsStyle} />
          <div className={featuresListSectionStyle}>
            <HomeFeaturesList features={features} />
          </div>
          <div className={sideBurnsStyle} />
        </div>
      </Container>
      <div className='relative'>
        <div className='md:hidden border-t border-brownish-grey bg-light-tangerine absolute top-0 w-full mobile-rectangle'/>
        <MobileFeatures features={features.slice(0, 2)} />
        <MobileFeatures features={features.slice(2, 4)} reverse />
        <MobileFeatures noMargin features={features.slice(4)} />
      </div>
    </div>
  )
}

export default HomeFeatures
