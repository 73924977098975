import React from "react"
import Container from "../../utils/container"
import ctl from "@netlify/classnames-template-literals"
import Button from "../../utils/button"
import { StaticImage } from "gatsby-plugin-image"

const BecomeAPartner = () => {
  const containerStyle = ctl(`mb-[118px] md:mt-0 mt-12`)
  const wrapperStyle = ctl(
    `become-a-partner text-white md:px-16 px-0 
    md:pt-12 md:pb-12 pt-0 pb-0 md:mt-[-129px] mt-[-270px]
    md:text-left text-center relative rounded-xl overflow-hidden md:h-[310px]
    shadow-partner z-[11] md-max:w-[83vw] md-max:mx-auto
    `
  )

  const contentSectionStyle = ctl(
    `max-w-[360px] md:p-0 p-6 pb-0 md:mx-0 mx-auto`
  )

  const paragraphStyle = ctl(`md:text-[17px] text-sm leading-[1.64]`)

  const imageStyle = ctl(
    `md:!absolute md:right-[20px] 
    md:bottom-0 md:mb-0 -mb-1 md:w-6/12 w-full`
  )

  const buttonStyle = ctl(`!text-white !text-lg md:mt-[38px] inline-flex items-center !pb-0`)

  return (
    <Container size="medium" className={containerStyle}>
      <div className={wrapperStyle}>
        <section className={contentSectionStyle}>
          <h2 className='font-extrabold'>Become a Partner</h2>
          <p className={paragraphStyle}>
            Partner with Egmarkets, refer <br className='mobile-breakpoint'/> customers to our <br className='desktop-breakpoint'/>
            award-winning <br className='mobile-breakpoint'/> brand and earn as they trade.
          </p>

          <Button
            label="Get Started"
            type="tertiary"
            className={buttonStyle}
            hasArrow
            arrowLight
            url='https://clients.eagleglobalmarkets.com/#/ib/signup'
            isExternal
          />
        </section>

        <StaticImage
          src="../../../assets/images/pages/home/become-a-partner.png"
          placeholder="none"
          className={imageStyle}
        />
      </div>
    </Container>
  )
}

export default BecomeAPartner
