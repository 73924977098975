import React from "react"
import sliderArrow from "icons/slider-arrow.svg"

import ctl from "@netlify/classnames-template-literals"
const HomeSliderNavigationArrows = () => {
  return (
    <div className={navigationArrowsWrapperStyle}>
      <button aria-label="Previous slide" className={previousArrowStyle}>
        <img src={sliderArrow} className={arrowIconStyle} alt="" />
      </button>
      <button aria-label="Next slide" className={nextArrowStyle}>
        <img src={sliderArrow} className={arrowIconStyle} alt="" />
      </button>
    </div>
  )
}

const buttonStyle = ctl(
  `w-[57px] h-[57px] rounded-full bg-white 
  flex items-center justify-center home-slider-nav pointer-events-auto`
)

const arrowIconStyle = ctl(`inline-block`)
const previousArrowStyle = ctl(`${buttonStyle} previous-slider-arrow`)
const nextArrowStyle = ctl(
  `${buttonStyle} next-slider-arrow transform rotate-180`
)
const navigationArrowsWrapperStyle = ctl(
  `absolute w-full top-[50%] transform translate-y-[-50%] 
  pointer-events-none left-0 right-0 
  z-[1] px-[21px] lg:flex hidden justify-between `
)

export default HomeSliderNavigationArrows
