import React from "react"
import currencyIcon from "icons/opportunities/currency.svg"
import indicesIcon from "icons/opportunities/indices.svg"
import sharesIcon from "icons/opportunities/shares.svg"
import commoditiesIcon from "icons/opportunities/commodities.svg"
import ctl from "@netlify/classnames-template-literals"
import { DesktopBreakpoint } from "../../../breakpoints"

const opportunities = [
  {
    icon: currencyIcon,
    name: "Forex Trading",
    description: (
      <span className='md-max:max-w-[282px] block md-max:mx-auto'>
        Go long or short on EURUSD, <DesktopBreakpoint/>
        & other major, minor & exotic <DesktopBreakpoint/>
        currency pairs.
      </span>
    ),
    iconClassName: 'md:h-[50px] md:flex md:items-end'
  },
  {
    icon: indicesIcon,
    name: "Indices Trading",
    description: (
      <span className='md-max:max-w-[331px] block md-max:mx-auto'>
        Trade the DOW, DAX, FTSE <DesktopBreakpoint/>
        & all other Indices across the US, <DesktopBreakpoint/>
        European & Asian Markets.
      </span>
    ),
  },
  {
    icon: sharesIcon,
    name: "Stock Trading",
    description: (
      <span className='md-max:max-w-[344px] block md-max:mx-auto'>
        Trade stock CFDs of the world’s <DesktopBreakpoint/>
        <span className='md:hidden'>m</span><span className='md-max:hidden'>M</span>ost successful companies like <DesktopBreakpoint/>
        Tesla, Amazon & Facebook.
      </span>
    ),
  },
  {
    icon: commoditiesIcon,
    name: "Commodities Trading",
    description: (
      <span className='md-max:max-w-[318px] block md-max:mx-auto'>
        Enjoy leverage of up to 200:1 <DesktopBreakpoint/>
        when you trade commodities <DesktopBreakpoint/>
        like Gold, Silver and Oil.
      </span>
    ),
  },
]

const ListOfOpportunities = () => {
  const wrapperStyle = ctl(
    `grid md:grid-cols-2 md:gap-x-[108px] flex-shrink-0 
    gap-x-8 gap-y-[56px] lg:max-w-[578px] flex-grow`
  )
  return (
    <section className={wrapperStyle}>
      {opportunities.map((opportunity, index) => {
        const { icon, name, description, iconClassName } = opportunity

        const opportunityStyle = ctl(`group md:text-left md:w-full text-center`)

        const iconStyle = ctl(` md:mx-0 mx-auto`)

        const nameStyle = ctl(` mt-[14px] text-xl text-sm `)
        const descriptionStyle = ctl(
          `mt-2 font-regular md:font-medium text-base leading-6`
        )
        return (
          <div className={opportunityStyle} key={index}>
            <div className={iconClassName}>
              <img src={icon} alt="" className={iconStyle} />
            </div>
            <h4 className={nameStyle}>{name}</h4>
            <p className={descriptionStyle}>{description}</p>
          </div>
        )
      })}
    </section>
  )
}

export default ListOfOpportunities
