import React from "react"
import Container from "../../../utils/container"
import ListOfOpportunities from "./list"
import ctl from "@netlify/classnames-template-literals"

import rightBarrelShape from "icons/opportunities/shape-right.svg"
import Chart from "./charts"
import leftBarrelShape from "icons/opportunities/shape-left.svg"

const ExploreOpportunities = () => {
  return (
    <div className="relative">
      <Container size="medium" className={containerStyle}>
        <h2 className='text-center font-bold text-[30px] md:text-[45px]'>
          Explore Opportunities In the financial markets.
        </h2>
        <p className='md-max:text-base mx-auto font-medium text-center text-xl'>
          Seize market opportunities with customer-optimized trading experience.
        </p>

        <div className={wrapperStyle}>
          <ListOfOpportunities />

          <Chart />
        </div>
      </Container>

      <img src={leftBarrelShape} alt="" className={barrelLeftStyle} />
      <img src={rightBarrelShape} alt="" className={barrelRightStyle} />
    </div>
  )
}

const containerStyle = ctl(`mb-12 !max-w-[1000px] bg-white`)
const wrapperStyle = ctl(`my-[60px] md:my-[90px] flex md:flex-row flex-col justify-between`)


const barrelLeftStyle = ctl(
  `shape md:block hidden left-0 bottom-[-160px]
   transform translate-x-[-65%]
   1.5xl-max:w-[350px]
  `
)

const barrelRightStyle = ctl(`
shape md:block hidden right-0 top-[-60px]
transform translate-x-[65%]
1.5xl-max:w-[350px]
`)

export default ExploreOpportunities
