import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import TradingPlaformItem from "./item"
import ctl from "@netlify/classnames-template-literals"
import { DesktopBreakpoint } from "../../../../breakpoints"

const platforms = [
    {
        image: (
            <StaticImage
                src="../../../../../assets/images/pages/home/trading-platforms/eagletrade.webp"
                placeholder="none"
                alt=""
            />
        ),
        name: "Eagletrade",
        url: "eagletrade",
        description: (
            <span className='block md-max:max-w-[230px]'>
                Our multi asset trading platform <DesktopBreakpoint/> gives you access to financial markets <DesktopBreakpoint/> in your local currency.
          </span>
        ),
        features: [
            "No foreign exchange hassle",
            "Web and mobile support",
            <span>
        Simple & intuitive trading <br/> experience.
      </span>,
        ],
      comingSoon: true
    },
    {
        image: (
            <StaticImage
                src="../../../../../assets/images/pages/home/trading-platforms/meta-trader-4.webp"
                placeholder="none"
                alt=""
            />
        ),
        name: "Meta Trader 4",
        buttonName: "MT4",
        url: "mt4",
        description: (
            <span className='block md-max:max-w-[230px]'>
        Powerful trading tool, convenient for <DesktopBreakpoint/> technical analysis, allowing you to <DesktopBreakpoint/> explore the financial markets in USD.
      </span>
        ),
        features: [
            "Trusted trading platform",
            "Web and mobile support",
            "50+ Technical Indicators",
        ],
    },
    {
        image: (
            <StaticImage
                src="../../../../../assets/images/pages/home/trading-platforms/copy-trade.webp"
                placeholder="none"
                alt=""
            />
        ),
        name: "Copytrade",
        url: "copytrade",
        description: (
          <span className='block md-max:max-w-[228px]'>
        Take advantage of the expertise of <DesktopBreakpoint/> professional traders to make highly <DesktopBreakpoint/> viable trades.
      </span>
        ),
        features: [
            "Find expert traders",
            "Follow their trades",
            (
                <span>
          Earn from trading without <br/> knowing how to trade.
        </span>
            ),
        ],
    },
    {
        image: (
            <StaticImage
                src="../../../../../assets/images/pages/home/trading-platforms/copy-trade.webp"
                placeholder="none"
                alt=""
            />
        ),
        name: "Eagletrade",
        url: "eagletrade",
        description:
            "An advanced trading platform, Combining the strengths of MT4 and Cloudtrade",
        features: [
            "No foreign exchange hassle",
            "Web and mobile support",
            "Simple & intuitive trading experience.",
        ],
        hide: true
    },
].filter(platform => !platform.hide)

const TradingPlatformsList = () => {
    const wrapperStyle = ctl(
        `
        flex md:flex-row flex-col
        justify-center md:w-full
        md:mx-0 mx-auto md:gap-10 gap-16
        lg:gap-x-[100px]
        mt-[60px] md:mt-[100px]
        `
    )
    return (
        <div className={wrapperStyle}>
            {platforms.map((platform, index) => (
                <TradingPlaformItem key={index} platform={platform}/>
            ))}
        </div>
    )
}

export default TradingPlatformsList
