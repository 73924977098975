import React from "react"
import GoldMobile from "./gold-mobile"
import ForexMobile from "./forex-mobile"
import OilMobile from "./oil-mobile"
import NasdaqMobile from "./nasdaq-mobile"
import BitcoinMobile from "./bitcoin-mobile"
import TeslaMobile from "./tesla-mobile"

const MobileMap = ({className}) => {
  return (
    <svg className={className} width="406" height="319" viewBox="0 0 406 319" fill="none" xmlns="http://www.w3.org/2000/svg">
      <GoldMobile/>
      <ForexMobile/>
      <OilMobile/>
      <NasdaqMobile/>
      <BitcoinMobile/>
      <TeslaMobile/>
      <defs>
        <filter id="filter0_d_104_66" x="22" y="227.266" width="127.774" height="57.295" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="-3" dy="3"/>
          <feGaussianBlur stdDeviation="2.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.25098 0 0 0 0 0.678431 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_104_66"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_104_66" result="shape"/>
        </filter>
        <filter id="filter1_d_104_66" x="-3" y="95" width="128.238" height="57.7531" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="-3" dy="3"/>
          <feGaussianBlur stdDeviation="2.5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.25098 0 0 0 0 0.678431 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_104_66"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_104_66" result="shape"/>
        </filter>
        <filter id="filter2_d_104_66" x="104" y="29" width="127.774" height="57.295" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="-3" dy="3"/>
          <feGaussianBlur stdDeviation="2.5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.25098 0 0 0 0 0.678431 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_104_66"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_104_66" result="shape"/>
        </filter>
        <filter id="filter3_d_104_66" x="82" y="0" width="177.774" height="107.295" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="-1"/>
          <feGaussianBlur stdDeviation="15"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.25098 0 0 0 0 0.678431 0 0 0 0.098 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_104_66"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_104_66" result="shape"/>
        </filter>
        <filter id="filter4_d_104_66" x="159.208" y="155.266" width="128.17" height="57.295" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="-3" dy="3"/>
          <feGaussianBlur stdDeviation="2.5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.25098 0 0 0 0 0.678431 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_104_66"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_104_66" result="shape"/>
        </filter>
        <filter id="filter5_d_104_66" x="137.208" y="126.266" width="178.17" height="107.295" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="-1"/>
          <feGaussianBlur stdDeviation="15"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.25098 0 0 0 0 0.678431 0 0 0 0.098 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_104_66"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_104_66" result="shape"/>
        </filter>
        <filter id="filter6_d_104_66" x="247" y="240.266" width="130.556" height="57.295" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="-3" dy="3"/>
          <feGaussianBlur stdDeviation="2.5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.25098 0 0 0 0 0.678431 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_104_66"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_104_66" result="shape"/>
        </filter>
        <filter id="filter7_d_104_66" x="225" y="211.266" width="180.556" height="107.295" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="-1"/>
          <feGaussianBlur stdDeviation="15"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.25098 0 0 0 0 0.678431 0 0 0 0.098 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_104_66"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_104_66" result="shape"/>
        </filter>
        <filter id="filter8_d_104_66" x="260.298" y="79.016" width="111.839" height="57.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="-3" dy="3"/>
          <feGaussianBlur stdDeviation="2.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.25098 0 0 0 0 0.678431 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_104_66"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_104_66" result="shape"/>
        </filter>
        <clipPath id="clip0_104_66">
          <rect width="406" height="319" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default MobileMap