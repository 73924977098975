import React from "react"
import { MarketOverview } from "react-tradingview-embed"
import ctl from "@netlify/classnames-template-literals"
import "./charts.css"

const Chart = () => {
  const chartWrapperStyle = ctl(
    `flex flex-col md:w-[300px] md:mt-0 mt-[70px] md-max:px-4 h-[514px] md:h-[450px] justify-between chart-div`
  )
  return (
    <div className={chartWrapperStyle}>
      <MarketOverview
        widgetProps={{ colorTheme: "light", height: "100%", width: "100%" }}
      />
    </div>
  )
}

export default Chart
