import React from "react"
import PropTypes from "prop-types"
import ctl from "@netlify/classnames-template-literals"

const TradingPlaformItemHeader = ({ image }) => {
  const headerStyle = ctl(`bg-blue bg-opacity-10 px-10 py-9`)
  return <header className={headerStyle}>{image}</header>
}

TradingPlaformItemHeader.propTypes = {
  image: PropTypes.node,
}

export default TradingPlaformItemHeader
