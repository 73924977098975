import React, { useMemo } from "react"
import { Container } from "../../utils"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { MobileBreakpoint } from "../../breakpoints"

const desktopAwards = [
  {
    title: 'Proshare Best forex trading platform in Nigeria 2018',
    id: '2018',
    className: 'w-[165px]'
  },
  {
    title: 'Proshare Best forex trading platform in Nigeria 2019',
    id: '2019',
    className: 'w-[165px]'
  },
  {
    title: 'Dama best choice of FX trading portal 2019',
    id: 'Dama',
    className: 'w-[106px]'
  },
  {
    title: 'Business Day 2018',
    id: 'business-day-2018',
    className: 'w-[96px]'
  },
  {
    title: 'Business Day 2019',
    id: 'business-day-2019',
    className: 'w-[96px]'
  }
]

const mobileAwards = [
  {
    title: 'Business Day 2018',
    id: 'business-day-2018',
    className: 'w-[96px]'
  },
  {
    title: 'Business Day 2019',
    id: 'business-day-2019',
    className: 'w-[96px]'
  }
]

const mobileAwards2 = [
  {
    title: 'Proshare Best forex trading platform in Nigeria 2018',
    id: '2018',
    className: 'w-[165px]'
  },
  {
    title: 'Proshare Best forex trading platform in Nigeria 2019',
    id: '2019',
    className: 'w-[165px]'
  }
]

const Awards = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter:{relativeDirectory:{eq:"pages/home/awards"}}) {
        nodes{
          name
          childImageSharp{
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    } 
  `)

  const fluidImages = useMemo(() => {
    const files = data.allFile.nodes

    return files.reduce((images, image) => {
      images[image.name] = image.childImageSharp?.gatsbyImageData

      return images
    }, {})
  }, [data])

  return (
    <Container size="large" className='mb-[200px] z-20 relative'>
      <h2 className='text-center font-bold text-[34px] md-max:w-[284px] mx-auto md:text-[40px]'>
        Public recognition of Excellence.
      </h2>
      <p className='text-center text-base md:text-[20px] md-max:w-[262px] mx-auto font-medium mb-10 md:mb-20'>
        We’ve been in our bags lately, <MobileBreakpoint/> here are a couple awards we’ve won.
      </p>
      <div className='md:hidden'>
        <GatsbyImage
          image={fluidImages['Dama']}
          alt='Dama best choice of FX trading portal 2019'
          className='mb-10 mx-auto w-[82px]'
        />
        <div className='flex items-center justify-between xs:px-8 mb-[60px]'>
          {
            mobileAwards.map(image => (
              <div className='w-[126px] flex justify-center'>
                <GatsbyImage
                  image={fluidImages[image.id]}
                  alt={image.title}
                  className='w-[73px]'
                />
              </div>
            ))
          }
        </div>
        <div className='flex items-center justify-between xs:px-8'>
          {
            mobileAwards2.map(image => (
              <GatsbyImage
                image={fluidImages[image.id]}
                alt={image.title}
                className='w-[126px]'
              />
            ))
          }
        </div>
      </div>
      <div className='md-max:hidden flex items-end justify-between flex-wrap gap-4'>
        {
          desktopAwards.map(image => (
            <GatsbyImage
              image={fluidImages[image.id]}
              alt={image.title}
              className={image.className}
            />
          ))
        }
      </div>
    </Container>
  )
}

export default Awards