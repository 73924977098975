import React from "react"
import Container from "../../../utils/container"
import ctl from "@netlify/classnames-template-literals"
import TradingPlatformsList from "./list"

const HomeTradingPlatforms = () => {
  const containerStyle = ctl(`md:mt-[190px] md:mb-[100px] my-12`)
  return (
    <div className="relative">
      <Container size='header' className={containerStyle}>
        <h2 className='md:text-[50px] text-center text-[34px] font-bold'>
          Choose from our selection <br className='desktop-breakpoint' />
          of trading platforms.
        </h2>
        <TradingPlatformsList />
      </Container>
    </div>
  )
}

export default HomeTradingPlatforms
