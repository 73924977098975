import React from "react"
import { HomeCopytrading, LearnFromMasters } from "../index"
import ctl from "@netlify/classnames-template-literals"
import "./index.css"

const HomeCtas = () => {
  return (
    <div className={wrapperStyling}>
      <HomeCopytrading />
      <LearnFromMasters />
      <div
        className={ctl(`
          border-t border-brownish-grey bg-light-tangerine
          absolute bottom-[100%] w-[100vw] h-[92px] -z-1 left-0 md:hidden
        `)}
      />
    </div>
  )
}

const wrapperStyling = ctl(`
  relative md:mt-[100px] md:border-t border-b border-brownish-grey bg-light-tangerine
  pt-[70px] md:pt-[84px] pb-[270px] md-max:pb-[300px] md-max:px-[30px]
`)

export default HomeCtas