import React from "react"
import ctl from "@netlify/classnames-template-literals"
import TradingPlaformItemHeader from "./item-header"
import Button from "../../../../utils/button"
import ComingSoonBadge from "../../../../coming-soon-badge"

const TradingPlaformItem = ({ platform }) => {
  const sectionStyle = ctl(`relative border border-blue rounded-lg pb-[30px] w-full md:w-[27%] flex flex-col md-max:max-w-[310px] md-max:mx-auto`)

  const contentWrapperStyle = ctl(`lg:px-8 md:px-4 px-2 flex-1 flex flex-col items-center mt-8 text-center`)

  const buttonStyle = ctl(`w-full max-w-[237px] bg-deep-blue border-deep-blue !mt-auto md-max:w-[220px] md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px]`)

  const descriptionStyle = ctl(`text-sm leading-[24px] md:text-base font-medium md:leading-[26px]`)

  const featuresListStyle = ctl(`text-left mt-12 mb-10 px-[10px]`)

  const featureListItemStyle = ctl(`mb-5  last:mb-0 flex`)

  const listBulletStyle = ctl(
    `block w-[10px] h-[10px] rounded-full bg-deep-blue flex-shrink-0`
  )

  const featureStyle = ctl(`my-0 pl-4 -mt-2`)
  return (
    <section className={sectionStyle}>
      {
        platform.comingSoon && (
          <ComingSoonBadge className='absolute top-[-15px] left-[50%] transform translate-x-[-50%]'/>
        )
      }
      <TradingPlaformItemHeader image={platform.image} />

      <div className={contentWrapperStyle}>
        <h3 className='font-bold text-2xl'>{platform.name}</h3>
        <p className={descriptionStyle}>{platform.description}</p>
        <ol className={featuresListStyle}>
          {platform.features.map((feature, index) => {
            return (
              <li className={featureListItemStyle} key={index}>
                <span className={listBulletStyle}/>
                <p className={featureStyle}>{feature}</p>
              </li>
            )
          })}
        </ol>
        <Button
          label={`Explore ${platform.buttonName || platform.name}`}
          url={`/platforms/${platform.url}`}
          className={buttonStyle}
        />
      </div>
    </section>
  )
}

export default TradingPlaformItem
