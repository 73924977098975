import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import InViewLottie from "../in-view-lottie"

const LadyWithPhone = () => {
  const { ladyWithPhone: { data } } = useStaticQuery(graphql`
    {
      ladyWithPhone{
        data
      }
    }
  `)

  return (
    <InViewLottie animationData={JSON.parse(data)} />
  )
}

export default LadyWithPhone