import React from "react"
import { TickerTape } from "react-tradingview-embed"

const HomeTicker = () => {
  return (
    <div className='my-[80px] md:mb-[120px]'>
      <TickerTape widgetProps={{ colorTheme: "light", height: "100px", displayMode: "adaptive" }} />
    </div>
  )
}

export default HomeTicker