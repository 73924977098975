import * as React from "react"
import Layout from "../components/layout"
import {
  BecomeAPartner,
  ExploreOpportunities,
  HomeFeatures,
  HomeSlider,
  HomeTradeWithHarry,
  HomeTradingPlatforms
} from "../components/pages/home"

import MediaMentions from "../components/utils/media"

import { ReceiveTradingSupport, TradingSteps, TradingTargets } from "../components/utils/trading"
import Testimonials from "../components/pages/about-us/testimonials"
import HomeTicker from "../components/pages/home/home-ticker"
import HomeCTAS from "../components/pages/home/home-ctas/home-ctas"
import Awards from "../components/pages/home/awards"

const IndexPage = () => (
  <Layout
    footerClassName='md:!mt-[84px]'
    title="Egmarkets | Trade the financial market with ease"
    description='Explore Opportunities In the financial markets. Join thousands of people trading forex, Indices, shares and commodities on our platform.'
    ignoreSiteName
  >
    <HomeSlider />
    <HomeTradeWithHarry />
    <HomeFeatures />
    <TradingTargets mergeWithTop/>
    <TradingSteps />
    <HomeTicker/>
    <ExploreOpportunities />
    <HomeTradingPlatforms />
    <ReceiveTradingSupport isHome withPattern={false}/>
    <HomeCTAS/>
    <BecomeAPartner />
    <Testimonials/>
    <Awards/>
    <MediaMentions className='md-max:mb-0 md:mb-[147px]' />
  </Layout>
)

export default IndexPage
