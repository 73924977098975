import React from "react"
import ctl from "@netlify/classnames-template-literals"
import { Container } from "components/utils"
import scribbleSvg from "icons/scribble.svg"
import harrySvg from "icons/harry.svg"
import RightArrowIcon from "../../../assets/icons/right-arrow"
import { Link } from "gatsby"

const HomeTradeWithHarry = () => {
  return (
    <Container size="medium" className={containerStyle}>
      <h3 className={headingStyle}>
        Trade with Harry
        <img src={scribbleSvg} className={scribbleStyle} alt="" />
      </h3>

      <div>
        <img src={harrySvg} alt="Harry New" className='md-max:w-[123px] md-max:relative md-max:left-[-9px]' />
      </div>
      <p className={paragraphStyle}>
        Meet Harry, the 24/7 virtual trading assistant you never knew you
        needed. Harry allows for a truly bespoke service for our traders and
        with his help, you can easily eliminate all the difficulties that come
        with trading the financial markets. <br />
        <Link to="/trade-with-harry/" className={linkStyle}>
          Learn more about Harry{" "}
          <RightArrowIcon arrowGreen/>
        </Link>
      </p>
    </Container>
  )
}

const containerStyle = ctl(
  `mt-[40px] md:mt-[60px] md:mb-0 mb-[60px] 
    flex md:flex-row flex-col
    md-max:items-center
    justify-between items-start md:text-left text-center
    md:!px-[10px]
   `
)
const headingStyle = ctl(`relative max-w-[240px] text-3xl md:mb-0 mb-[40px] md:mt-[10px]`)

const paragraphStyle = ctl(`md-max:max-w-[320px] max-w-[592px] md-max:mt-[30px] text-[17px]`)

const scribbleStyle = ctl(`absolute left-0 top-[100%] md:top-[unset] md:bottom-[-25px]`)

const linkStyle = ctl(`text-seafoam-green md:font-semibold mt-[20px] md:mt-[10px] text-lg flex items-center md-max:justify-center`)

export default HomeTradeWithHarry
